var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"headers":_vm.cols,"items":_vm.items,"options":_vm.table_options,"hide-default-footer":"","not_resizeble":"","no-data-text":"Связанные документы отсутствуют"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.register_realization_corrective_id)?_c('v-btn',{staticClass:"ml-n4",attrs:{"to":item.register_realization_corrective_id.toString(),"append":"","text":""}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.status)?_c('div',[_c('ChipStatus',{attrs:{"status":_vm.get_dispatch_status(item.status)}},[_vm._v(" "+_vm._s(item.status.name)+" ")])],1):_vm._e()])]}},{key:"item.events",fn:function(ref){
var item = ref.item;
return [_c('DownloadBtn',{attrs:{"src":item.file}})]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":_vm.set_page,"page_size_changed":_vm.set_per_page}})]},proxy:true}],null,true)},[_vm._v(" > ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }